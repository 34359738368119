import React, { useEffect, useState, useRef } from "react";
import logo from "../Assets/mrgenie_online_logo.png";
import backgroundImage from "../Assets/backgroundImage.png";
import chiragImage from "../Assets/chirag.png";
import GenieImage from "../Assets/genie.png";
import Genie from "./Genie";
import "./HeroSection.css";

const HeroSection = () => {
  const [isChiragSlided, setIsChiragSlided] = useState(false);
  const [isGenieVisible, setIsGenieVisible] = useState(false);
  const [isChiragVisible, setIsChiragVisible] = useState(true);
  const [isWishSectionVisible, setIsWishSectionVisible] = useState(false);

  const genieRef = useRef(null);
  const buttonsRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsChiragSlided(true);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (isChiragSlided) {
      setTimeout(() => {
        setIsGenieVisible(true);
      }, 1000);
    }
  }, [isChiragSlided]);

  const handleWish = () => {
    setIsWishSectionVisible((prevState) => !prevState);

    // Scroll to the Genie section only if on mobile
    if (window.innerWidth < 640 && genieRef.current) {
      genieRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSlack = () => {
    const slackOAuthUrl = `https://slack.com/oauth/v2/authorize?client_id=8163287008419.8163405352178&scope=chat:write,channels:read,users:read&redirect_uri=https://www.integromat.com/oauth/cb/slack3`;
    window.open(slackOAuthUrl, "_blank");
  };

  // Scroll to Genie section when it becomes visible
  useEffect(() => {
    if (isWishSectionVisible && window.innerWidth < 640 && genieRef.current) {
      // Scroll to the Genie section bottom
      setTimeout(() => {
        genieRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 100); // Small delay to ensure Genie is rendered
    }
  }, [isWishSectionVisible]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isClickInsideGenie =
        genieRef.current && genieRef.current.contains(event.target);

      const isClickInsideButtons =
        buttonsRef.current && buttonsRef.current.contains(event.target);

      const isClickOnChatHistoryButton = event.target.closest(
        ".rcb-view-history-button"
      );
      console.log(
        "Is click on Load Chat History button:",
        Boolean(isClickOnChatHistoryButton)
      );

      if (
        !isClickInsideGenie &&
        !isClickInsideButtons &&
        !isClickOnChatHistoryButton
      ) {
        console.log("Closing Genie as the click was outside.");
        setIsWishSectionVisible(false);
      } else {
        console.log("Click was inside Genie or allowed area.");
      }
    };

    if (isWishSectionVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isWishSectionVisible]);

  return (
    <div className="main font-Embed relative">
      <div
        className="min-h-screen bg-cover bg-center flex flex-col items-start justify-center pl-8 pr-8 sm:h-[80vh] lg:h-screen"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="px-8 sm:px-10 section justify-between space-y-8 sm:space-y-16">
          <div className="logo mt-4 sm:mt-0">
            <img src={logo} className="h-auto sm:h-20 " alt="Mr Genie" />
          </div>

          <div className="heading text-center md:text-left max-w-2xl">
            <h1 className="text-4xl md:text-6xl  font-medium text-white mb-4 ">
              Mr. Genie Is Online Ready for Some Fun?
            </h1>

            <p className="text-lg md:text-xl text-gray-200 mb-8">
              Get answers to all your questions, but be prepared for a cheeky
              reply!
            </p>
          </div>

          {/* Buttons container */}
          <div
            ref={buttonsRef}
            className="buttonDiv flex flex-col sm:flex-row items-center sm:space-x-4 space-y-4 sm:space-y-0"
          >
            <button
              onClick={handleWish}
              className="bg-[#D3B6FF] text-[#570039] border-2 border-black py-2 px-6 rounded-md text-xl font-extrabold hover:shadow-[5px_5px_0px_0px_#F796FF] transition-shadow duration-500 ease-in-out"
            >
              Make a Wish!
            </button>
            <button
              onClick={handleSlack}
              className="bg-[#D3B6FF] text-[#570039] hover:shadow-[5px_5px_0px_0px_#F796FF] transition-shadow duration-500 border-2 border-black py-2 px-6 rounded-md text-xl font-extrabold"
            >
              Add to Slack
            </button>
          </div>
        </div>

        {/* Chirag Image - Visible only on screens wider than 640px */}
        {!isWishSectionVisible && isChiragVisible && (
          <div className="absolute bottom-8 right-8 hidden sm:block">
            <img
              onClick={handleWish}
              src={chiragImage}
              alt="Hero"
              className={`w-auto h-20 rounded-full transform transition-all duration-1000 ease-out ${
                isChiragSlided ? "translate-x-0" : "translate-x-full"
              }`}
            />
          </div>
        )}

        {/* Genie Image - Visible only on screens wider than 640px */}
        {!isWishSectionVisible && isChiragSlided && isGenieVisible && (
          <div className="absolute bottom-20 right-32 hidden sm:block">
            <img
              onClick={handleWish}
              src={GenieImage}
              alt="Lala Genie"
              className="w-40 h-64 rounded-full animate-genie-raise"
            />
          </div>
        )}

        {isWishSectionVisible && (
          <div
            ref={genieRef}
            className="w-full sm:w-auto mt-4 sm:mt-0 sm:absolute sm:right-8 sm:top-16 lg:top-0 transform transition-all duration-500 ease-in-out"
          >
            <Genie />
          </div>
        )}
      </div>
    </div>
  );
};

export default HeroSection;
