import React, { useState } from "react";
import ChatBot from "react-chatbotify";
import botAvatar from "../Assets/bot_avatar.svg";

function Genie() {
  const [isChatBotOpen, setIsChatBotOpen] = useState(true);

  const handleCloseChatBot = () => {
    setIsChatBotOpen(false);
  };

  const flow = {
    start: {
      message: "Hello! I am Mr. Genie, your wish is my command! 🧞‍♂️",
      path: "handleWish",
    },
    handleWish: {
      message: async (params) => {
        try {
          console.log("Received params:", params);

          const requestBody = { wish: params.userInput };
          console.log("Sending request:", requestBody);

          const response = await fetch("/api/api.php", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify(requestBody),
            mode: "cors",
          });

          if (!response.ok) {
            const errorText = await response.text();
            console.error("Server response:", errorText);
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          return data.response || "Sorry, I couldn't process that wish.";
        } catch (error) {
          console.error("Error:", error);
          return "I apologize, but I'm having trouble processing your wish right now. Please try again later.";
        }
      },
      path: "handleWish",
    },
  };

  return (
    <div className="flex flex-col">
      <div className="w-full flex justify-center p-4">
        {isChatBotOpen && (
          <div className="border-0 shadow-lg">
            <ChatBot
              settings={{
                header: {
                  title: "Mr. Genie",
                  showAvatar: true,
                  avatar: botAvatar,
                  buttons: [
                    <button
                      key="close-button"
                      className="text-white  px-2 py-1"
                      onClick={handleCloseChatBot}
                      aria-label="Close chatbot"
                    >
                      ✖
                    </button>,
                  ],
                },
                general: {
                  showFooter: false,
                  embedded: true,
                  botName: "Mr. Genie",
                  primaryColor: "#3411d1",
                  secondaryColor: "#8c439a",
                },
                chatHistory: {
                  storageKey: "mrgenie_chat_history",
                  maxEntries: 30,
                  viewChatHistoryButtonText: "Load Chat History ⟳",
                  className: "rcb-view-history-button",
                },
              }}
              flow={flow}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Genie;
