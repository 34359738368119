import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HeroSection from "./Components/HeroSection";
import Genie from "./Components/Genie";

const App = () => {
  return (
    <Router>
      {/* Define Routes */}
      <Routes>
        <Route path="/" element={<HeroSection />} />
        <Route path="/genie" element={<Genie />} />
      </Routes>
    </Router>
  );
};

export default App;
